var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Grupo",
                    "data-vv-as": "Grupo",
                    name: "group",
                  },
                  model: {
                    value: _vm.group.group,
                    callback: function ($$v) {
                      _vm.$set(_vm.group, "group", $$v)
                    },
                    expression: "group.group",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("group"),
                        expression: "errors.has('group')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("group")))]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }